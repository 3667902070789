@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap");
* {
  font-family: "Roboto Serif", serif;
}

::-webkit-scrollbar {
  width: 0px;
}

.logo {
  width: 80px;
  padding: 6px;
}

.repheader {
  padding: 8px 30px;
  background: linear-gradient(
    180deg,
    rgba(120, 50, 94, 1) 0%,
    rgba(169, 81, 139, 1) 35%,
    rgba(120, 50, 94, 1) 100%
  );
}
.repheader p {
  margin-bottom: 0px !important;
  color: #fff !important;
  font-size: 13px;
}
.navbar {
  padding: 10px 40px;
  /* background-color: #803361 !important; */
  height: 80px;
  background-image: url('./tracker02.jpg');
  background-size: cover;
}
.navbar-collapse {
  justify-content: space-between;
}

.breadcumb-banner {
  padding: 70px 40px 70px;
  background-image: url("./banner-04-.jpg");
  background-size: cover;
}
.breadcrumb {
  display: flex;
  justify-content: left;
}
.abouttext {
  padding: 30px;
  line-height: 30px;
}

.abouttext h3 {
  background: linear-gradient(to right, #732a5a, #a8508a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  margin-bottom: 20px;
}
.breadcrumb li a {
  color: #fff;
  font-family: "Roboto Serif", serif;
  font-size: 28px;
  text-decoration: none;
  margin-bottom: 0px;
  font-weight: 500;
  text-transform: uppercase;
}

.miisionission {
  background-image: url('./20240628_133222.jpg');
  padding: 50px 40px;
}

.mbox {
  background-color: #fff;
  padding: 20px;
}
.miisionission h5 {
  color: #803361;
  margin-bottom: 20px;
  font-size: 26px;
}
.foundermesage {
  padding: 40px 40px;
}
.foundermesage img {
  border-radius: 5px;
}
.lucknowteam {
  padding: 30px 40px;
}

.playerpict {
  margin-bottom: 20px;
}
.founderquote {
  padding: 30px;
}
.foundermesage p {
  font-size: 17px;
  line-height: 32px;
}
.breadcrumb-item {
  /* Optional styling for breadcrumb items */
}

.breadcrumb-item.active {
  /* Optional styling for the active (current) page */
}

.playerpict img {
  border-radius: 5px;
}
.lionicon {
  width: 40px;
  margin-top: -10px;
}

nav li a {
  padding: 4px 10px !important;
  color: #78325e !important;
  margin-right: 15px;
  text-decoration: none;
  font-size: 16px !important;
  text-transform: uppercase;
  font-weight: 600 !important;
}

nav li {
  display: inline-block; /* Ensures each list item is inline */
  position: relative; /* Needed for absolute positioning */
}
.nav-item {
  display: inline-block; /* Ensures list items are inline */
  margin-right: 10px; /* Adjust spacing between items */
}

.nav-item:not(:last-child) {
  border-right: 2px solid #a8508a33; /* Add border separator */
  padding: 0px 10px;
}

.nav-link {
  text-decoration: none; /* Remove underline from links */
  color: #333; /* Set link color */
}

.nav-link:hover {
  text-decoration: underline; /* Underline links on hover */
}
.sponsor {
  background: linear-gradient(
    180deg,
    rgba(120, 50, 94, 1) 0%,
    rgba(169, 81, 139, 1) 35%,
    rgba(120, 50, 94, 1) 100%
  );

  border: 0px;
  padding: 8px 20px;
  border-radius: 6px;
  color: #fff;
  margin-top: 13px;
}

.bannerimg img {
  border-radius: 0px;
  background-size: cover;
  width: 100%;
}

footer {
  background: linear-gradient(
    180deg,
    rgba(120, 50, 94, 1) 0%,
    rgba(169, 81, 139, 1) 35%,
    rgba(120, 50, 94, 1) 100%
  );
  padding: 20px 20px;
}
footer li {
  color: #fff !important;
  list-style-type: none;
  margin-right: 30px;
  margin-bottom: 0px;
}

.foundermesage h3 {
  background: linear-gradient(to right, #732a5a, #a8508a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  margin-bottom: 30px;
}
.footernav ul {
  display: flex;
  margin-bottom: 0px;
}
.fixtureright {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  height: 350px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-size: cover;
  background-position: left;
  background-color: #fff;
}

.date {
  width: 100%;
}
.date p {
  color: #fff;
  text-align: center;
  font-size: 18px;
}

video {
  width: 100%;
  border-radius: 5px !important;
}
.playerdata {
  background-image: url("./20240628_133222.jpg");
  background-size: cover;
  padding: 40px 0px 40px 30px;
}

.copyright {
  background-color: #a9518b;
  padding: 10px;
  font-size: 11px;
}
.copyright p {
  color: #fff;
  text-align: center;
  font-size: 14px !important;
  margin-bottom: 0px;
}
.fixturesection {
  background-image: url('./tracker02.jpg');
  background-size: cover;
  padding: 40px 0px 40px 30px;
}
.fixtureright img {
  max-width: 80px !important;
  box-shadow: rgb(141 27 102 / 20%) 0px 8px 24px;
  border-radius: 40px;
  margin: auto;
}

.stadium {
  border: 1px solid #8d1b66;
  margin-top: 10px;
  color: #fff;
}
.stadium p {
  color: #8d1b66 !important;
  padding: 5px;
}

.newheading h5 {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.fixtureright h3 {
  color: #fff;
  background-color: #8d1b66;
  margin-top: 10px;

  font-size: 18px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 5px;
  width: FIT-CONTENT;
  margin: 10px AUTO;
  padding: 3px 20px;
  margin-bottom: 10px;
}
.fixturecard {
  border-radius: 5px !important;
  height: 320px;
  /* border: 1px solid #FFE5B7 ; */
}

.fixtureright p {
  color: #8d1b66;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0px;
}
.fixtureleft h6 {
  font-size: 32px;
  font-weight: bold;
  color: #fff;

  text-align: center;
  margin-top: 40px;
  right: 9px;
  position: relative;
}
.fixtureleft {
  width: 100%;
  background-size: cover;
  padding: 20px;
  height: 240px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

/* standing */

.highlight td:first-child {
  border-left: 8px solid #803361 !important;
  margin-left: 10px;
}

.vs h6 {
  font-size: 18px;
  color: #fff;
  text-align: center;
  font-family: "oswald";
  margin-top: 30px;
}

table td {
  font-size: 14px;
  font-weight: 600;
  border: 0px solid #fff;
  background-color: #fff !important;
  color: #2f2b60 !important;
  vertical-align: middle;
}
table th {
  background-color: #803361 !important;
  font-weight: 400;
  color: #fff !important;
  border: 0px;
}
table img {
  display: block;
  margin: auto;
  width: 100%;
  width: 55% !important;
}
.table th:nth-child(1),
.table td:nth-child(1) {
  width: 5%; /* Adjust the width percentage as needed */
  font-weight: 500;
}

.table th:nth-child(2),
.table td:nth-child(2) {
  width: 1%; /* Adjust the width percentage as needed */
}


.table th:nth-child(3),
.table td:nth-child(3) {
  width: 15%; /* Adjust the width percentage as needed */
}
.table th:nth-child(4),
.table td:nth-child(4) {
  width: 25%; /* Adjust the width percentage as needed */
}

.table th:nth-child(5),
.table th:nth-child(6),
.table th:nth-child(7),
.table th:nth-child(8),
.table td:nth-child(4),
.table td:nth-child(5),
.table td:nth-child(6),
.table td:nth-child(7),
.table td:nth-child(8) {
  width: 6%; /* Adjust the width percentage as needed */
}
table img {
  width: 100%;
  margin: auto;
  display: block;
  width: 66% !important;
}
table ul {
  display: flex;
  list-style-type: none;
  margin-bottom: 0;
}
.loss,
.win {
  border-radius: 20px;
  color: #fff;
  height: 30px;
  line-height: 30px;
  margin-left: 10px;
  text-align: center;
  font-weight: 500;
  width: 30px;
}
.win {
  background-color: #329553;
}
.loss {
  background-color: #f3504f;
}
.table-bordered > :not(caption) > * > * {
  border-bottom: 1px solid #8c6b3b;
  border: 0px;
}

.isqulaify{
  background: rgb(50,149,83);
  color: #FFF;
  /* POSITION: absolute; */
  margin-top: 8px;
  padding: 5px 10px;
  /* margin-left: 8px; */
  border-radius: 10px;

}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
  .isqulaify {
    background: rgb(50, 149, 83);
    color: #FFF;
    POSITION: absolute;
    margin-top: -14px;
    padding: 5px 10px;
    margin-left: -8px;
    border-radius: 3px;
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after {
  content: " ";
  display: table;
}

/*mnajiri*/

.contents.widget-content {
  padding: 5px !important;
}

/*mnajiri*/

.contents.widget-content {
  padding: 15px;
}

div#match_today h2 {
  width: fit-content;
  text-align: center;
  padding: 10px;
  font-size: 21px;
  font-weight: 600;
  color: #7f3663;
}

/*mnajiri*/

.mnajiri {
  display: block;
  height: 94px;
  position: relative;

  box-shadow: rgba(0, 0, 0, 0.57) 0px 25px 20px -21px;

  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;

  border-radius: 5px;
  margin: 12px auto;

  background: #9b487e91;
}

/*mnajiri*/

.fullink {
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 5;
}

/*mnajiri*/

.mnajiriin {
  margin: 0 13px;
}

/*mnajiri*/

.mnajiri img {
  background-color: #fff;
  border-bottom: 3px solid #532855;
  height: 70px;
  padding: 4px;
  position: absolute;
  width: 70px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 2px 5px -1px rgba(0, 0, 0, 0.2);
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}

/*mnajiri*/

.mpart1 strong {
  color: #fff;

  height: 50px;

  margin-right: -50px;

  position: absolute;

  right: 50%;

  text-align: center;

  width: 100px;

  z-index: 1;

  border-radius: 5px;
}

.mpart1 {
  position: relative;
  height: 48px;
  line-height: 48px;
}

.mpart1 span {
  float: right;

  text-align: center;

  width: 100%;

  z-index: 2;
}

/*mnajiri*/

.right_match {
  float: right;
  padding-left: 30px;
  width: 40%;
  position: relative;
  z-index: 2;
}

/*mnajiri*/

.left_match {
  float: left;
  padding-right: 30px;
  width: 40%;
  position: relative;
  z-index: 2;
}

.details_match {
  padding: 0 100px;
  font-size: 14px;
}

.details_match i {
  margin-left: 5px;
}
.auctionimg{
  margin: 20px auto;
}
.details_match span {
  font-size: 12px;
  background-color: #803361;
  border-left: 1px solid #fff;
  color: #fff;
  float: right;
  height: 36px;
  line-height: 36px;
  position: relative;
  text-align: center;
  width: 33.3%;
}

.details_match .liga_mdw {
  border: 0 none;
}

.first_match::before,
.liga_mdw::before {
  background-color: #803361;
  content: "";
  height: 36px;
  position: absolute;
  top: 0;
  width: 20px;
}

.first_match::before {
  transform: skew(-20deg);
  right: -12px;
  border-radius: 0 0 8px;
}

.liga_mdw::before {
  transform: skew(20deg);
  left: -12px;
  border-radius: 0 0 0 8px;
}

@media screen and (max-width: 700px) {
 
  .details_match {
    padding: 0 60px;
  }
  .details_match span {
    width: 50%;
  }
  .thany::before {
    background-color: #803361;
    content: "";
    height: 36px;
    position: absolute;
    top: 0;
    width: 20px;
    transform: skew(20deg);
    left: -12px;
    border-radius: 0 0 0 8px;
  }
}

@media screen and (max-width: 500px) {
  .gadwal {
    font-size: 14px;
    width: 106%;
    background: #fff;
  }
}

.details_match {
  font-size: 12px;
}

/*mnajiri*/

.mpart1 {
  line-height: 20px;
}

/*mnajiri*/

.mnajiriin {
  margin: 0 5px;
}

/*mnajiri*/

.right_match {
  padding-left: 20px;
}

.left_match {
  padding-right: 20px;
}

/*mnajiri*/

.mnajiri {
  height: 130px;
}

.details_match {
  padding: 0 25px;
}

/*mnajiri*/

.mnajiri img {
  position: relative;
  display: block;
  margin: 5px auto;
  background: transparent;
  border: 0;
  padding: 0;
}

.mpart1 strong {
  height: 36px;
  line-height: 36px;
  top: 30px;
  width: 60px;
  margin-right: -30px;
}

.najiri1 {
  background-color: #803361;
}

.najiri2 {
  background-color: #e91e63;
}

.najiri3 {
  background-color: #4caf50;
}

.founderquote h6{
  color: #723163;
  font-size: 18px;
  font-weight: 700;

}

.watchnow{
  padding: 30px;
}

.watchbanner{
  background:#77325e42;
  border-radius: 10px;
}
.mnone{
  display: block  !important;
}
.owl-dots , .owl-nav{
  display: none!important;
}
@media only screen and (max-width: 600px) {
  .mnone{
    display: none!important;
  }
  footer {
    display:none!important
  }
  .repheader{
    display: none;
  }
  .newheading h5{
    font-size: 22px;
  }
  .playerdata{
    padding: 20px 10px;
  }
  .lionicon{
    margin-top: -6px;
    width: 26px;
}
  .breadcumb-banner{
    padding: 40px 20px;
  }
  .abouttext h3{
    font-size: 18px!important;
  }
  .logo{
    margin-top: -10px;
  }
  .fixturesection{
    padding: 20px 10px;
  }
  .watchnow{
    padding: 10px!important;
  }
  th{
    white-space: nowrap;
  }
  td{
    font-size: 10px!important;
  }
  table img{
    width: 68px !important;
  }
  .fixturesection{
    padding: 20px 10px!important;
  }
  .owl-nav , .owl-dots{
    display: none!important;
  }
  .abouttext {
    padding: 20px 10px!important;
  }
  .breadcrumb {
margin-bottom: 0px!important;
  }
  .mbox{
    margin-bottom: 20px;
  }
  .miisionission ,.foundermesage{
    padding: 20px 10px;
  }
  .breadcrumb li a{
    font-size: 22px!important;
  }
  .lucknowteam{
    padding: 20px 10px;
  }
  .navbar-collapse 
{
  background-color: #fff;
}
.navbar-toggler{
  border: none!important;
}
.navbar{
  padding: 0px 12px;
}
th{
    font-size: 11px !important;
    font-weight: 200 !important;
}
.copyright {
  font-size: 8pz!important;
}
nav li a{
  font-size: 14px!important;
}
.founderquote{
  padding: 0px!important;
}
p{
    font-size: 12px !important;
    line-height: 20px;
}
.founderquote h6{
  color: #723163;
  font-size: 14px;
  font-weight: 700;

}
.foundermesage p{
  margin-top: 20px;
  line-height: 20px!important;
}
.abouttext h3{
  font-size: 18px!important;
}
div#match_today h2{
  font-size: 19px;
}
}